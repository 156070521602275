import React, {useEffect } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import Layout from "../../components/layout";

// import GoogleAds from "../components/googleads";

import "../../assets/css/main.css";

const query = graphql`
  query {
    strapiHomepage {
      hero {
        title
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;


export default function Bajardepeso() {

  const data = useStaticQuery(query);

  return (
    <Layout seo={data.strapiHomepage.seo}>
    <div className="bg-fondolead bg-center bg-cover mb-20 col-span-1 opacity-85 py-20">
        <div className="max-w-5xl mx-auto text-center px-4 sm:px-6 lg:px-8 ">
            <h1 className="max-w-3xl m-auto poppins pt-12 md:text-5xl text-4xl leading-11 pb-2 text-morado">Descarga el kit <span className="font-bold">"5 errores que cometes</span> para bajar de peso y no sabes"</h1>
            <h3 className="text-center poppins text-2xl m-auto pb-12 max-w-xl leading-7 pt-2">Completa el formulario y te enviaremos por email el ebook con las herramientas</h3>
          </div>
          <div className="max-w-5xl mx-auto py-12 px-4 sm:px-6 lg:py-4 lg:px-8 flex justify-center ">
          <form method="POST" action="https://redcobot.activehosted.com/proc.php" id="_form_37_" class="_form _form_37 _inline-form  _dark poppins" novalidate>
            <input type="hidden" name="u" value="37" />
            <input type="hidden" name="f" value="37" />
            <input type="hidden" name="s" />
            <input type="hidden" name="c" value="0" />
            <input type="hidden" name="m" value="0" />
            <input type="hidden" name="act" value="sub" />
            <input type="hidden" name="v" value="2" />
            <input type="hidden" name="or" value="518f4e4352b2080dab8cab3bbc0549f0" />
            <div class="_form-content">
                <ul className="list-none ">
                    <li className="mt-2 max-w-xs"><input type="text" id="field[2]" name="field[2]" placeholder="Escribe tu nombre" required className="w-full px-5 py-3 placeholder-gray-500 focus:ring-morado focus:border-morado  border-gray-300 rounded-md"/></li>
                    <li className="mt-2 max-w-xs"><input type="text" id="field[3]" name="field[3]" placeholder="Escribe tus apellidos" required className="w-full px-5 py-3 placeholder-gray-500 focus:ring-morado focus:border-morado  border-gray-300 rounded-md"/></li>
                    <li className="mt-2 max-w-xs"><input type="text" id="email" name="email" placeholder="Escribe tu email" required className="w-full px-5 py-3 placeholder-gray-500 focus:ring-morado focus:border-morado  border-gray-300 rounded-md"/></li>
                    <li className="mt-2 max-w-lg items-center flex"><input type="checkbox" id="field_1Acepto la política de privacidad" name="field[1][]" value="Acepto la política de privacidad" required/> <span className="ml-2">Acepto la <a href="/politica-de-privacidad" target="_blank" className="text-black font-bold decoration-solid">política de privacidad</a></span></li>
                    <li className="mt-6 ">
                        <div className="mt-8 rounded-md  sm:mt-0">
                            <button
                            id="_form_37_submit"
                            className='w-20 flex items-center text-black justify-center px-5 py-3 border border-transparent text-base font-medium font-poppins rounded-md  bg-amarillofooter hover:bg-morado hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amarillotransparente'
                            role='submit'>
                                Enviar
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
            </form>
            </div>
        </div>

    </Layout>
  )
}